<template>
  <div id="defaultId1" class="company_detail">
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false">
      <div>
        <h2 id="gf7b16" class="operations-head detail_title_20 margin-bottom20">
          <div>
            <span class="operations-headspan" v-if="activeIEports == '1'">
              List of National Trade Enterprises
            </span>
            <span class="operations-headspan" v-if="activeIEports == '2'">
              List of National Trade Enterprises
            </span>
            <el-select style="width:  14.2857143rem; margin-left: 40px" v-model="Importyear" placeholder="Please Select"
              @change="ImportYear">
              <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-select style="width: 14.28rem; margin-left: 40px" v-model="ImportPuctervalue"
              placeholder="Please Select" @change="ImportPucterYear">
              <el-option v-for="item in ImportPucter" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
          </div>

          <div class="flex_between_box">
            <div class="flex_center_start_box margin-right20">
                <svg t="1733203174144" class="icon" viewBox="0 0 1112 1024" version="1.1"  @click="CheckClickBottom(activeIconBottom)"
                xmlns="http://www.w3.org/2000/svg" p-id="7052"  width="2.57rem" height="2.57rem">
                <path
                  d="M1046.556427 70.759203H565.146651V16.067592a16.067592 16.067592 0 0 0-16.067592-16.067592h-21.011467a16.067592 16.067592 0 0 0-16.067592 16.067592v54.691611H65.815329A65.506337 65.506337 0 0 0 0 136.26554V710.681955a65.506337 65.506337 0 0 0 65.815329 65.506337h401.689801l-130.085697 170.872662a34.916113 34.916113 0 0 0 0 44.185878 23.792396 23.792396 0 0 0 37.38805 0l154.496078-203.62583v-1.544961 211.041642a26.573325 26.573325 0 0 0 53.14665 0v-201.153893L741.581171 986.302957a24.101388 24.101388 0 0 0 37.079058 2.471937 35.225106 35.225106 0 0 0 2.162945-44.185878l-139.973446-168.091732h405.706699A65.506337 65.506337 0 0 0 1112.371756 710.681955V136.26554a65.506337 65.506337 0 0 0-65.815329-65.506337zM1059.534098 710.681955a12.97767 12.97767 0 0 1-12.977671 12.668679H65.815329a12.97767 12.97767 0 0 1-12.977671-12.668679V136.26554A12.668678 12.668678 0 0 1 65.815329 123.596862h980.741098a12.668678 12.668678 0 0 1 12.977671 12.668678z"
                  :fill="activeIconBottom ? '#009EA7' : '#8497AB'" p-id="7053"></path>
                <path
                  d="M905.038021 233.907061h-238.541944a26.573325 26.573325 0 0 0 0 53.146651h238.541944a26.573325 26.573325 0 0 0 0-53.146651zM905.038021 357.503923h-238.541944a26.573325 26.573325 0 0 0 0 53.14665h238.541944a26.573325 26.573325 0 0 0 0-53.14665z"
                  :fill="activeIconBottom  ? '#009EA7' : '#8497AB'" p-id="7054"></path>
                <path d="M639.922752 481.100785l251.519614 0 0 52.837658-251.519614 0 0-52.837658Z" fill="#685ED1"
                  p-id="7055"></path>
                <path d="M639.922752 604.697646l198.681955 0 0 52.837659-198.681955 0 0-52.837659Z" fill="#685ED1"
                  p-id="7056"></path>
                <path
                  d="M326.604707 591.410984a167.78274 167.78274 0 1 1 0-335.256488V203.007846a220.92939 220.92939 0 1 0 220.620399 220.620398H494.387447a168.091732 168.091732 0 0 1-167.78274 167.78274z"
                  :fill="activeIconBottom ? '#009EA7' : '#8497AB'" p-id="7057"></path>
                <path
                  d="M372.95353 406.015691h132.248643a28.427278 28.427278 0 0 0 28.118286-33.989137c-11.741702-58.090525-48.82076-170.254677-157.585999-178.288473a29.045263 29.045263 0 0 0-30.899216 28.73627v154.496077a28.73627 28.73627 0 0 0 28.118286 29.045263z"
                  :fill="activeIconBottom  ? '#009EA7' : '#8497AB'" p-id="7058"></path>
              </svg>
            </div>
           
          
            <div id="ge4e89" class="u41151">
           
              <div id="g8b81a" @click="CheckClick('table1')"
                :class="activeIcon == 'table1' ? 'ub7903 u72482' : 'ub7903'">
                <svg id="idex5xpV" class="icon" t="1718690711396" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="4563" width="2.57rem" height="2.57rem">
                  <path id="id3aCJoQ"
                    d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                    :fill="activeIcon == 'table1' ? '#009EA7' : '#8497AB'" p-id="4564"></path>
                </svg>
              </div>
              <div id="g66b8c" @click="CheckClick('computer1')"
                :class="activeIcon == 'computer1' ? 'ub7903 u72482' : 'ub7903'">
                <svg id="idNxDoHC" class="icon" t="1718690674036" viewBox="0 0 1214 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="4367" width="2.57rem" height="2.57rem">
                  <path id="idT6sPU1"
                    d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                    :fill="activeIcon == 'computer1' ? '#009EA7' : '#8497AB'" p-id="4368"></path>
                  <path id="iddq3HRE"
                    d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                    :fill="activeIcon == 'computer1' ? '#009EA7' : '#8497AB'" p-id="4369"></path>
                </svg>
              </div>
            </div>
            <div class="perations-content-left margin-left20">
              <span :class="activeIEports == '1'
                ? 'perations-button perations-button-active'
                : 'perations-button'
                " @click="ceckIEports('1')">
                Imports</span>
              <span @click="ceckIEports('2')" :class="activeIEports == '2'
                ? 'perations-button perations-button-active'
                : 'perations-button'
                ">Exports</span>
            </div>
          </div>
        </h2>

        <div class="flex_center_between_box">
          <div v-show="activeIcon == 'computer1'" class="Products_lesft" style="height: auto">
            <div ref="SupplyChainEchart" style="width: 98%; height: 100%; min-height: 40rem"></div>
          </div>
          <div v-if="activeIcon == 'table1'" class="table-biao table-biao-left" style="position: relative">
            <el-table :data="ImporttableData" ref="table" empty-text="No Data" style="width: 100%"
              class="table_height_30 table_font_size_14 table_contentHeight_40">
              <el-table-column label="#" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">Top {{ scope.$index }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="name" label="Origin" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="" style="color: #1290c9; cursor: pointer">{{
                    scope.row.name
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="num" label="Transactions" align="center">
                <template slot-scope="scope">
                  <div class="flex_center">
                    <span class="table-content">{{ scope.row.num }}</span>
                    <!-- </el-tooltip> -->
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </gui_card>

    <gui_card id="ge6845" class="uc1096" label="" v-if="activeIconBottom" :isShowLabel="false" style="margin-top: 10px">
      <div>
        <h2 class="operations-head flex_center_bettwen">
          <span class="head-titel detail_title_20">List of Trade Data of Foreign Trade Companies from Other Countries
          </span>
          <svg @click="CheckClickBottom(activeIconBottom)" t="1718863593010" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="4791" width="30" height="30">
            <path
              d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z"
              fill="#333333" p-id="4792"></path>
          </svg>
        </h2>
        <h2 id="gf7b16" class="operations-head detail_title_20 margin-bottom20">
          <div style="width: 100%; display: flex" class="margin-top20">
            <el-date-picker v-model="datePicker" @change="SelectPicker" style="width: 20.2857143rem; height: 3.571428rem"
              type="monthrange" range-separator="" start-placeholder="Start date" class="margin-right20"
              value-format="yyyy-MM" end-placeholder="End date"></el-date-picker>
            <el-select filterable style="width:  14.2857143rem" v-model="Coutryoption" placeholder="Please Select"
              @change="ImportYear">
              <el-option v-for="item in Coutryoptions" :key="item.val" :label="item.value" :value="item.val">
              </el-option>
            </el-select>
            <el-select class="margin-right20" style="width:  14.2857143rem; margin-left: 20px" v-model="ImportYes"
              placeholder="Please Select" @change="ImportYear">
              <el-option v-for="item in Importoptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-autocomplete id="c308fb" clearable v-model="HscodeDes" style="width: 60.4%; height: auto"
              :highlight-first-item="true" :debounce="300" :trigger-on-focus="false" :autofocus="true" class="u0fcfc"
              :fetch-suggestions="querySearchAsync" placeholder="Please Seach" size="large">
            </el-autocomplete>
          </div>
        </h2>
        <div class="table-biao" style="position: relative; width: 100%">
          <el-table :data="ImporttableDataTwo" ref="table" empty-text="No Data" style="width: 100%"
            class="table_height_30 table_font_size_14 table_contentHeight_40">
            <el-table-column prop="Date" align="left" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="table-content"> {{ scope.row.Date }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="HSCode" label="HS Code" align="left" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="" style="color: #1290c9; cursor: pointer">{{
                  scope.row.HSCode
                }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="Products" label="Products" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.Products }}</span>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column prop="DestinationContry" label="Destination Contry" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="table-content">{{
                  scope.row.DestinationContry
                }}</span>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column prop="OriginContry" label="Origin Contry" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="table-content">{{ scope.row.OriginContry }}</span>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column prop="Amount" label="Amount(USD M)" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="table-content">{{ scope.row.Amount }}</span>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
    </gui_card>

    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>

  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import { getNews, getCountries } from "@/api/companyApi";
import { productSearch } from "@/api/searchApi.js";

import rectcard from "@/components/rectCard/index.vue";
import content_text from "@/components/text_content/index.vue";
import gui_card from "@/components/gui_card/gui_card.vue";
import ReLogin from "@/components/ReLogin.vue";
//importEnd
import { mixins1 } from "@/mixins/index";
export default {
  name: "",

  props: {},
  mixins: [mixins1],
  components: {
    // gui_card start
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    ReLogin,
  },
  computed: {},
  mounted() {
    this.GetCountry();
    this.getNewsImportList();
    this.suppleEchart();
  },
  data() {
    return {
      reLoginVis: false,
      createDialog: false,
      HscodeDes: "",
      activeIEports: "1",
      Importyear: "2024-12",
      activeIcon: "computer1",
      Coutryoptions: [],
      Coutryoption: "US",
      datePicker: "",
      ImportPuctervalue: "CommodityProducts",
      ImportPucter: [
        {
          label: "Commodity Products",
          value: "CommodityProducts",
        },
        {
          label: "Service Products",
          value: "ServiceProducts",
        },
      ],

      ImporttableDataTwo: [
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
        {
          Date: "2024",
          HSCode: "854142",
          Products:
            "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display",
          DestinationContry: "China",
          OriginContry: "Singapore",
          Amount: "0.01",
        },
      ],
      Yearoptions: [
        {
          label: "2024-12",
          value: "2024-12",
        },
        {
          label: "2024-11",
          value: "2024-11",
        },
        {
          label: "2024-10",
          value: "2024-10",
        },
        {
          label: "2024-09",
          value: "2022-09",
        },

      ],
      ImportYes: "Imports",
      Importoptions: [
        {
          label: "Imports",
          value: "Imports",
        },
        {
          label: "Exports",
          value: "Exports",
        },
      ],
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      keyNum: 0,
      SearchHscode: "",
      SelectHscode: "",

      ImporttableData: [],
      ExportTableData: [],

      staticDataImport: [],
      staticDataExport: [],
      activeIconBottom:true,
      newDialog: false,
      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  methods: {
    querySearchAsync(queryString, cb) {
      if (queryString !== "") {
        const params = {
          keyword: queryString,
        };
        productSearch(params)
          .then((res) => {
            const data = res.data;
            if (res.code) {
              data.forEach((item) => {
                item.val = item.hsCode;
                item.value = item.hsCode + "-" + item.hsCodeDescription;

                // item.val=item.value
              });

              cb(data);
            } else {
              cb([]);
            }
          })
          .catch(() => {
            cb([]);
          });
      }
    },
    suppleEchart() {
      let that = this;

      this.echartsBox2 = this.$echarts.init(this.$refs.SupplyChainEchart2);

      const option2 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          right: "2%",
          data: ["Email",],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "0%",
          top: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Email",
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },

        ],
      };

      this.echartsBox2.setOption(option2);
      this.$nextTick(() => {
        this.echartsBox2.resize();
      });
    },
    SelectPicker() {
    },
    GetCountry() {
      let Allcountry = JSON.parse(localStorage.getItem("countryList"))[0]
        .countryList;
      Allcountry.forEach((item) => {
        item.value = item.nameEn;
        item.val = item.countryCode;
      });
      this.Coutryoptions = Allcountry;
    },
    CheckClick(value) {
      this.activeIcon = value;
      if (value == "computer1") {
        // this.$nextTick(()=>{
        this.getNewsImportList();

        // })
      }
    },
    CheckClickBottom(CheckClickBottom){
    this.activeIconBottom=!CheckClickBottom
    },
    ceckIEports(row) {
      this.activeIEports = row;
      this.getNewsImportList();
    },
    ImportYear() {
      this.getNewsImportList();
    },
    ImportPucterYear() { },
    ExportYear() { },
    ImportsuppleEchart() {
      this.staticDataImport.map((item) => {
        let countryImg = this.getCountryImgUrl(item[3]);
        item[3] = require("../../../../../static" + countryImg);
      });

      const startYear = this.Importyear; // 选择特定年份
      const source = this.staticDataImport.slice(1).filter(function (d) {
        return d[1] === startYear; // 过滤出特定年份的数据
      });
      source.sort((a, b) => b[2] - a[2]);
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      const option = {
        grid: {
          top: "5%",
          bottom: 30,
          left: 150,
          right: 20,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            var tar = params[0];
            return tar.axisValueLabel + " : " + tar.value;
          },
        },
        xAxis: {
          type: "value",
          position: "bottom", // 将 xAxis 放置在底部
          offset: 10, // 向下移动 10
          axisLabel: {
            show: true,
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
            formatter: function (value) {
              return value.length > 10 ? value.substring(0, 10) + "..." : value; // 超过10个字符则省略
            },
          },
          axisTick: { show: false },
        },

        yAxis: {
          type: "category",
          inverse: true,
          boundaryGap: false,
          axisLabel: {
            show: true,
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
            formatter: function (value) {
              return value.length > 10 ? value.substring(0, 10) + "..." : value; // 超过10个字符则省略
            },
          },
          data: source.map((item) => item[0]), // 添加国家名称作为 x 轴数据
          // position: 'bottom',
          // offset: -10,
          axisLine: { show: false },
          axisTick: { show: false },
        },
        series: [
          {
            type: "bar",
            barWidth: "25",
            backgroundStyle: { color: "#F5F5F5" },
            itemStyle: { color: "#009EA7" },
            emphasis: {
              itemStyle: { color: "#009EA7" },
            },

            data: source.map((item) => item[2]), // 只保留值
          },
        ],

        graphic: {
          elements: source.map((item, index) => {
            const countryName = item[0]; // 假设国家名称在数据的第一列
            const flagPath = item[3]; // 获取对应的图标路径（假设图标路径在数据的第四列）
            return {
              type: "image",
              id: `flag-${index}`,
              style: {
                image: flagPath,
                width: 30, // 设置图标宽度
                height: 20, // 设置图标高度
              },
              left: 20, // 设置图标的 x 位置
              top: 30 + index * 90, // 设置图标的 y 位置，确保每个图标不重叠
              z: 10,
            };
          }),
        },
      };

      this.echartsBox.setOption(option);
      this.$nextTick(() => {
        this.echartsBox.resize();
      });
    },

    getNewsImportList() {
      getCountries({
        aaaId: "156107587326",
        year: this.Importyear,
        type: this.activeIEports,
      }).then((res) => {
        if (res.code == 200) {
          this.ImporttableData = res.data.countryRankingFormVOS;
          this.staticDataImport = res.data.objects;
          this.staticDataImport.unshift([
            "Country",
            "Year",
            "Life Expectancy",
            "CountryCode",
          ]),
            this.ImportsuppleEchart();
        }
      });
      // getfilterConditions()
    },

    ShowAll() { },
  },
};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: "DMSans Bold";
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.table-biao {
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  /* margin-top: 1.4285714285714286rem; */
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: "DMSans Bold";
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: "DMSans Bold";
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: "DMSans Bold";
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

@media screen and (max-width: 1441px) {
  .contclass {
    font-size: 14px;
  }
}

.table_add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_add_Text {
  font-family: "DMSans Bold";
  text-align: left;
}

.table-content {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  line-height: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.impact_status {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  text-align: center;
  border-radius: 50%;
}

.impact_status img {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  margin-top: 0.5rem;
}

.impact_status.green {
  background: #55b419;
}

.impact_status.hui {
  background: #ccc;
}

.impact_status.blue {
  background: #1290c9;
}

.impact_status.red {
  background: #ff7600;
}

.input-str {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 13.214285714285714rem;
  z-index: 99;
  text-align: 7.142857142857143rem;
  width: 26%;
}

.input-str /deep/ .el-input__inner {
  margin-top: -0.14285714285714285rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142rem solid #8497ab;
}

.uUIRzUi {
  padding: 2.857142857142857rem;
}

.Products_lesft {
  width: 100%;
}

.table-biao {
  width: 100%;
}

.table-biao-left /deep/ .el-table td.el-table__cell {
  border-right: none;
  height: 3.71rem;
}

/* .operations-content {
  padding: 30px 40px;
  background: #FFFFFF;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
} */

.table-content-color {
  color: #009ea7;
}

.u41151 {
  display: flex;
  width: 10rem;
  border-radius: 1.7857142857142858rem;
  /* background: #f5f5f5; */
  padding: 0.21428571428571427rem;
}

.ub7903 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.142857142857143rem;
  border-radius: 1.5714285714285714rem;
}

.u72482 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.142857142857143rem;
  border-radius: 1.5714285714285714rem;
}

.Products_line {
  height: 40rem;
  width: 100%;
}

.bottom_charts {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  margin-right: 4.28571429rem;
}

/* .u5e22d {
    font-size: 2.14rem;
    font-family: 'DMSans Bold';
} */
</style>
